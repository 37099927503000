export async function FortniteApi() {

    const endpoint = 'https://fortniteapi.io/v1/items/upcoming?lang=en';
    const apiKey = '79ffc96e-5e1693d2-1e7613e3-cea331e3';
    
    try{
        const response = await fetch(endpoint, {
            headers: {
                Authorization: apiKey
            }
        });

        if (response.ok) {
            const jsonResponse = await response.json();
            
            const itemsArray = jsonResponse.items;

            const list = Object.keys(itemsArray).map(key => {
                return itemsArray[key];
            });

            console.log(list);
            return list;
        }
    }
    catch (error) {
        console.log(error);
    }

}  