import React from 'react';
import './App.css';
import { Link } from 'react-router-dom';

function Nav() {
  return (
    <div>
        <div className="navImage">

        </div>
        <nav>
        <div>
            <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/dreams">Dreams</Link></li>
            <li><Link to="/settings">Settings</Link></li>
            </ul>
        </div>
        </nav>
    </div>
  );
}

export default Nav;
