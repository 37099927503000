import React from 'react';
import './App.css';
import {FortniteApi} from './FortniteApi';

class Settings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            testarray: []
        }
    }

    async componentDidMount() {
        const apiArray = await FortniteApi();
        this.setState({ testarray: apiArray });
    }

    render() {
        return (
            <div>
                <h1>Fortnite shit!</h1>               
                {this.state.testarray.map(element => (
                    <div key={element.id}>
                        <img src={element.images.icon} alt="" width="100px" />
                        <p>{element.name}</p>
                    </div>
                ))}       
            </div>
        );
    }
  
}

export default Settings;